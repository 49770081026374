export enum MimeType {
  Blob = 'application/octet-stream',
  FormData = 'multipart/form-data',
  Json = 'application/json',
  Xls = 'application/vnd.ms-excel',
  Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Kml = 'application/vnd.google-earth.kml+xml',
  Csv = 'text/csv',
  Pan = 'application/x-pan',
  Ond = 'application/ond',
  Srw = 'application/srw',
  Xlsm = 'application/xlsm',
}
